/* https://gomakethings.com/how-to-add-transition-animations-to-vanilla-javascript-show-and-hide-methods/ */
var slideShow = function (elem) {

	// Get the natural height of the element
    // Even though we’re showing and then re-hiding our content, it never shows visibly for users because 
    // the function runs so quickly (just a few milliseconds) and thus never actually renders visually in the DOM.
	var getHeight = function () {
		elem.style.display = 'block'; // Make it visible
        console.log(elem.scrollHeight);
		var height = elem.scrollHeight + 'px'; // Get it's height
		elem.style.display = ''; //  Hide it again
		return height;
	};

	var height = getHeight(); // Get the natural height
	elem.classList.add('is-visible'); // Make the element visible
	elem.style.height = height; // Update the max-height

    // Once the transition is complete, remove the inline max-height so the content can scale responsively
	window.setTimeout(function () {
		elem.style.height = '';
	}, 350);

};

//slide out to hide
var slideHide = function (elem) {

	// Give the element a height to change from
	elem.style.height = elem.scrollHeight + 'px';

	// Set the height back to 0
	window.setTimeout(function () {
		elem.style.height = '490px';
	}, 1);

	// When the transition is complete, hide it
	window.setTimeout(function () {
		elem.classList.remove('is-visible');
        elem.style.height = '';
	}, 350);

};

// Toggle sliding element visibility
var toggle = function (elem, timing) {

	// If the element is visible, hide it
	if (elem.classList.contains('is-visible')) {
		slideHide(elem);
		return;
	}

	// Otherwise, show it
	slideShow(elem);
	
};

document.addEventListener('click', function (event) {
    if (event.target.matches('.js-toggle-slide')){

        event.preventDefault();
        
        console.log('clicked button')

        let contentBody = event.target.previousElementSibling;
        toggle(contentBody);
        event.target.classList.toggle('toggled');
    }
});